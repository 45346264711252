import React from 'react';
import { Navbar } from 'reactstrap';
import './NavMenu.css';

const NavMenu = () => {
    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
            </Navbar>
        </header>
    );
}

export default NavMenu;
