const _BuildOptions = (method, data, user) => {
    let request = {
        method: method,
        headers: {
        }
    };

    if(user?.token){
        request.headers.Authorization = `Bearer ${user.token}`;
    }

    if (!(data instanceof FormData)) {
        request.headers["Content-Type"] = "application/json";
    }

    if (data) {
        if (data instanceof FormData) {
            request.body = data;
        }
        else {
            request.body = JSON.stringify(data);
        }
    }

    return request;
}

const DefaultQueryFn = async ({ queryKey }) => {

    const response = await fetch(`${queryKey}`, _BuildOptions('GET'));

    if (!response.ok) {
        throw new Error("Network response was not Ok");
    }

    return await response.json();
}

const POST = async (url, data, method = 'POST', user) => {

    const response = await fetch(url, _BuildOptions(method, data, user))

    if (response.status === 204) {
        return response;
    }

    if (response.status === 401) {
        return Promise.reject("login");
    }

    if (response.ok) {
        return await response.json() || response.statusText
    }

    return Promise.reject(await response.json() || response.statusText);
};

export { POST, DefaultQueryFn }