import { useState, useEffect } from "react";

function getStorageValue(key, defaultValue) {
    const saved = localStorage.getItem(key);
    const initial = JSON.parse(saved);
    return initial || defaultValue;
}

const useLocalStorage = (key = "user", defaultValue) => {
    const [value, setValue] = useState(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        value
            ? localStorage.setItem(key, JSON.stringify(value))
            : localStorage.removeItem(key);

    }, [key, value]);

    return [value, setValue];
};

/*const useLocalStorage = (name) => {
    const set = (value) => {
        value
            ? localStorage.setItem(name, JSON.stringify(value))
            : localStorage.removeItem(name);
    };

    const get = () => {
        return JSON.parse(localStorage.getItem(name)) || undefined;
    };

    return [get(), set];
};*/

export default useLocalStorage;