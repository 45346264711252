import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useForm, FormProvider } from "react-hook-form";

import Input from "../components/Input";
import { POST } from "../hooks/Constants";
import { useAuth } from "../components/AuthContext";

const Login = () => {

    const { setUser } = useAuth();
    const methods = useForm();
    const navigator = useNavigate();

    const mutation = useMutation(data => POST("/api/agents/accounts", data));

    const onSubmit = data => {
        mutation.mutate(data, {
            onSuccess: (rsp) => {
                setUser(rsp);
                navigator("/portal/campaigns");
            }
        })
    };

    return (
        <div className="row">
            <div className="col-md-4">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Input label="Username" name="userName" register={methods.register} required errors={methods.formState.errors} />
                        <Input label="Password" name="password"  required register={methods.register} errors={methods.formState.errors} type="password" />

                        <input type="submit" disabled={mutation.isLoading} />

                        {mutation.isLoading && <div className="alert alert-info mt-3"> Processing... </div>}
                        {mutation.isError && <div className="alert alert-danger mt-3">{mutation.error.message}</div>}
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

const Portal = () => {
    const { isAuthenticated } = useAuth();

    return (
        <>
            {!isAuthenticated && <Login />}
            {isAuthenticated && <Link to="/portal/campaigns">Campaigns</Link>}
        </>
    );
};

export default Portal;
