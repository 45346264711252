import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div className="row mt-5">
                <div className="text-center">
                    <img src="/img/logo.png" className="mb-2" alt="LOGO" />
                    <h3 className="fw-bold">The Super Marketing Group</h3>
                    <h1 className="display-1">Partners Portal</h1>
                    <p className="small mt-3">Unauthorised access prohibited</p>
                </div>
            </div>
        );
    }
}
