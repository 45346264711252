import React, { Suspense, lazy } from "react";
import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";

const importView = (template) =>
  lazy(() =>
    import(`./RegistrationViews/${template}`).catch(() =>
      import(`./RegistrationViews/Default`)
    )
  );

const RegistrationContainer = () => {
  const [params] = useSearchParams();
  let { key } = useParams();

  const { isLoading, data } = useQuery(`/api/campaign?campaignKey=${key}`);

  if (isLoading) return <p> Loading...</p>;

  if (data && data?.isSuccess === true) {
    const View = importView(data.result.template);

    return (
      <Suspense fallback={<div>Loading...</div>}>
        <View
          campaignKey={key}
          campaignName={data.result.name}
          agentRef={params.get("agentReference")}
          regNote={params.get("regNote")}
        />
      </Suspense>
    );
  }

  return (
    <div className="row text-center">
      <div className="col-md-4">
        {!isLoading && <div className="row">Campaign is Required!</div>}
      </div>
    </div>
  );
};

export default RegistrationContainer;
