import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import { POST } from "../../hooks/Constants";
import Input from "../../components/Input";

const ResetPassword = () => {
    const methods = useForm();
    const navigator = useNavigate();
    const [params] = useSearchParams();
    const token = params.get("t");

    const mutation = useMutation(data => POST("/api/agents/accounts/reset-password", data));

    const onSubmit = data => {
        mutation.mutate({ ...data, token: token }, {
            onSuccess: () => {
                navigator("/portal");
            }
        });
    };

    return (
        <div className="row">
            <h3> Reset Password </h3>
            <div className="col-md-4">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Input
                            label="Password"
                            name="password"
                            required
                            register={methods.register}
                            errors={methods.formState.errors}
                            type="password"
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        />
                        <Input
                            label="Confirm Password"
                            name="confirmPassword"
                            required
                            register={methods.register}
                            errors={methods.formState.errors}
                            type="password"
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        />
                        <input type="submit" disabled={mutation.isLoading} />
                        {mutation.isLoading && <div className="alert alert-info mt-3">Processing... </div>}
                        {mutation.isError && <div className="alert alert-danger mt-3">An error occurred: {mutation.error.error.message}</div>}
                    </form>
                </FormProvider>
            </div>
        </div>
    );
}

export default ResetPassword;
