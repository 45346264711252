import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import "./custom.css";

import { AuthContext } from "./components/AuthContext";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";

import RegistrationContainer from "./screen/RegistrationContainer";
import DefaultThankyou from "./screen/DefaultThankyou";
import Campaigns from "./screen/Agents/Campaigns";
import Portal from "./screen/Portal";

import useLocalStorage from "./hooks/useLocalStorage";

import { isAfter, parseISO } from "date-fns";
import ResetPassword from "./screen/Agents/ResetPassword";

const PrivateRoute = ({ isAuthenticated, children }) => {
    return isAuthenticated ? children : <Navigate to="/portal" />;
};

const isValid = (exp) => {
    const now = new Date();
    const _isAfter = isAfter(parseISO(exp), now);
    return _isAfter;
}

const App = () => {
    const [user, setUser] = useLocalStorage("user");
    const isAuthenticated = user && isValid(user.expires);

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, setUser }}>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="consumer-reg" element={<RegistrationContainer />}>
                        <Route path=":key" element={<RegistrationContainer />} />
                    </Route>
                    <Route path="/consumer-reg/:key/thankyou" element={<DefaultThankyou />} />

                    <Route path="pub" element={<RegistrationContainer />}>
                        <Route path="campaign" element={<RegistrationContainer />}>
                            <Route path=":key" element={<RegistrationContainer />} />
                        </Route>
                    </Route>
                    <Route path="pub/campaign/:key/thankyou" element={<DefaultThankyou />} />
                    
                    <Route path="/portal" element={<Portal />} />
                    <Route path="/portal/reset-password" element={<ResetPassword />} />
                    <Route
                        path="/portal/campaigns"
                        element={
                            <PrivateRoute isAuthenticated={isAuthenticated}>
                                <Campaigns />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/portal/campaigns/:key/thankyou"
                        element={
                            <PrivateRoute isAuthenticated={isAuthenticated}>
                                <DefaultThankyou />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/portal/campaigns/:key"
                        element={
                            <PrivateRoute isAuthenticated={isAuthenticated}>
                                <RegistrationContainer />
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </Layout>
        </AuthContext.Provider>
    );
};

export default App;
