import React from "react";
import _ from "lodash";

const Input = ({ id,
    label,
    register,
    formState: { errors },
    disabled = false,
    validate = {},
    onChange = e => { },
}) => {
    const error = _.get(errors, id);
    return (
        <div className="mb-3">
            <label className="form-label" htmlFor={id}>{label}</label>
            <input className="form-control" type="text" {...register(id, { validate: validate, onChange })} disabled={disabled} />
            {error && <span htmlFor={id} className="form-text text-danger">{error?.message}</span>}
        </div>
    );
}

export default Input;