import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from "react-query";

const DefaultThankyou = () => {
    const { key } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const { data } = useQuery(`/api/campaign?campaignKey=${key}`, {
      enabled: !!key
    });

    const membershipNumber = params.get("mn");

    return (
      <div className="text-center">
        <h2 className="display-4">{data?.result.name ?? "Campaign Name"}</h2>
        <h3>Thank you</h3>
        {
            membershipNumber && <h5>Membership Number: <u>{membershipNumber}</u></h5>            
        }
      </div>
    );
}

export default DefaultThankyou;
