import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import { useAuth } from "../../components/AuthContext";

const Campaigns = () => {
    const { user } = useAuth();
    
    const fetchAgent = async () =>
        await (await fetch("/api/agents", {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        })).json();

    const { data } = useQuery("/api/agents", fetchAgent);

    return (
        <div>
            <h3>Campaigns </h3>
            {
                data && data.result.campaigns.map((c, i) =>
                    <div key={i}>
                        <Link to={`/portal/campaigns/${c.code}`}>{c.name}</Link>
                    </div>
                )
            }
        </div>
    );
}

export default Campaigns;
